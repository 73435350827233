
/**
 * @roxi/routify 2.18.12
 * File generated Mon Oct 07 2024 10:20:07 GMT+0100 (British Summer Time)
 */

export const __version = "2.18.12"
export const __timestamp = "2024-10-07T09:20:07.201Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports


//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isFallback": true,
      "path": "/_fallback",
      "component": () => import('../src/routes/_fallback.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_admin__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/admin/admin",
          "id": "_admin_admin",
          "component": () => import('./_admin__layout_svelte.js').then(m => m._admin_admin)
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isPage": true,
              "meta": {
                "$$bundleId": "_admin__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/admin/file_maker_products/FileMakerProductList",
              "id": "_admin_file_maker_products_FileMakerProductList",
              "component": () => import('./_admin__layout_svelte.js').then(m => m._admin_file_maker_products_FileMakerProductList)
            },
            {
              "isPage": true,
              "meta": {
                "$$bundleId": "_admin__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/admin/file_maker_products/MoreDetailsPopup",
              "id": "_admin_file_maker_products_MoreDetailsPopup",
              "component": () => import('./_admin__layout_svelte.js').then(m => m._admin_file_maker_products_MoreDetailsPopup)
            },
            {
              "isPage": true,
              "meta": {
                "$$bundleId": "_admin__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/admin/file_maker_products/UpdatePopup",
              "id": "_admin_file_maker_products_UpdatePopup",
              "component": () => import('./_admin__layout_svelte.js').then(m => m._admin_file_maker_products_UpdatePopup)
            }
          ],
          "meta": {
            "$$bundleId": "_admin__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/admin/file_maker_products"
        },
        {
          "isIndex": true,
          "isPage": true,
          "meta": {
            "$$bundleId": "_admin__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/admin/index",
          "id": "_admin_index",
          "component": () => import('./_admin__layout_svelte.js').then(m => m._admin_index)
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isPage": true,
              "meta": {
                "$$bundleId": "_admin__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/admin/languages/LanguageList",
              "id": "_admin_languages_LanguageList",
              "component": () => import('./_admin__layout_svelte.js').then(m => m._admin_languages_LanguageList)
            },
            {
              "isPage": true,
              "meta": {
                "$$bundleId": "_admin__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/admin/languages/LanguagePreview",
              "id": "_admin_languages_LanguagePreview",
              "component": () => import('./_admin__layout_svelte.js').then(m => m._admin_languages_LanguagePreview)
            },
            {
              "isPage": true,
              "meta": {
                "$$bundleId": "_admin__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/admin/languages/MoreDetailsPopup",
              "id": "_admin_languages_MoreDetailsPopup",
              "component": () => import('./_admin__layout_svelte.js').then(m => m._admin_languages_MoreDetailsPopup)
            },
            {
              "isPage": true,
              "meta": {
                "$$bundleId": "_admin__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/admin/languages/UpdatePopup",
              "id": "_admin_languages_UpdatePopup",
              "component": () => import('./_admin__layout_svelte.js').then(m => m._admin_languages_UpdatePopup)
            }
          ],
          "meta": {
            "$$bundleId": "_admin__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/admin/languages"
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_admin__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/admin/package",
          "id": "_admin_package",
          "component": () => import('./_admin__layout_svelte.js').then(m => m._admin_package)
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isPage": true,
              "meta": {
                "$$bundleId": "_admin__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/admin/products/PreviewProduct",
              "id": "_admin_products_PreviewProduct",
              "component": () => import('./_admin__layout_svelte.js').then(m => m._admin_products_PreviewProduct)
            },
            {
              "isPage": true,
              "meta": {
                "$$bundleId": "_admin__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/admin/products/PriceList",
              "id": "_admin_products_PriceList",
              "component": () => import('./_admin__layout_svelte.js').then(m => m._admin_products_PriceList)
            },
            {
              "isPage": true,
              "meta": {
                "$$bundleId": "_admin__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/admin/products/ProductsList",
              "id": "_admin_products_ProductsList",
              "component": () => import('./_admin__layout_svelte.js').then(m => m._admin_products_ProductsList)
            },
            {
              "isPage": true,
              "meta": {
                "$$bundleId": "_admin__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/admin/products/UpdateProduct",
              "id": "_admin_products_UpdateProduct",
              "component": () => import('./_admin__layout_svelte.js').then(m => m._admin_products_UpdateProduct)
            }
          ],
          "meta": {
            "$$bundleId": "_admin__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/admin/products"
        }
      ],
      "isLayout": true,
      "ownMeta": {
        "bundle": true
      },
      "meta": {
        "bundle": true,
        "$$bundleId": "_admin__layout_svelte.js",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/admin",
      "id": "_admin__layout",
      "component": () => import('./_admin__layout_svelte.js').then(m => m._admin__layout)
    },
    {
      "isDir": true,
      "children": [
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_bardic__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/bardic/country",
          "id": "_bardic_country",
          "component": () => import('./_bardic__layout_svelte.js').then(m => m._bardic_country)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_bardic__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/bardic/details",
          "id": "_bardic_details",
          "component": () => import('./_bardic__layout_svelte.js').then(m => m._bardic_details)
        },
        {
          "isIndex": true,
          "isPage": true,
          "meta": {
            "$$bundleId": "_bardic__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/bardic/index",
          "id": "_bardic_index",
          "component": () => import('./_bardic__layout_svelte.js').then(m => m._bardic_index)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_bardic__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/bardic/package",
          "id": "_bardic_package",
          "component": () => import('./_bardic__layout_svelte.js').then(m => m._bardic_package)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_bardic__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/bardic/payment",
          "id": "_bardic_payment",
          "component": () => import('./_bardic__layout_svelte.js').then(m => m._bardic_payment)
        }
      ],
      "isLayout": true,
      "ownMeta": {
        "bundle": true
      },
      "meta": {
        "bundle": true,
        "$$bundleId": "_bardic__layout_svelte.js",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/bardic",
      "id": "_bardic__layout",
      "component": () => import('./_bardic__layout_svelte.js').then(m => m._bardic__layout)
    },
    {
      "isDir": true,
      "children": [
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_bardiconlineoffer__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/bardiconlineoffer/country",
          "id": "_bardiconlineoffer_country",
          "component": () => import('./_bardiconlineoffer__layout_svelte.js').then(m => m._bardiconlineoffer_country)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_bardiconlineoffer__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/bardiconlineoffer/details",
          "id": "_bardiconlineoffer_details",
          "component": () => import('./_bardiconlineoffer__layout_svelte.js').then(m => m._bardiconlineoffer_details)
        },
        {
          "isIndex": true,
          "isPage": true,
          "meta": {
            "$$bundleId": "_bardiconlineoffer__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/bardiconlineoffer/index",
          "id": "_bardiconlineoffer_index",
          "component": () => import('./_bardiconlineoffer__layout_svelte.js').then(m => m._bardiconlineoffer_index)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_bardiconlineoffer__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/bardiconlineoffer/package",
          "id": "_bardiconlineoffer_package",
          "component": () => import('./_bardiconlineoffer__layout_svelte.js').then(m => m._bardiconlineoffer_package)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_bardiconlineoffer__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/bardiconlineoffer/payment",
          "id": "_bardiconlineoffer_payment",
          "component": () => import('./_bardiconlineoffer__layout_svelte.js').then(m => m._bardiconlineoffer_payment)
        }
      ],
      "isLayout": true,
      "ownMeta": {
        "bundle": true
      },
      "meta": {
        "bundle": true,
        "$$bundleId": "_bardiconlineoffer__layout_svelte.js",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/bardiconlineoffer",
      "id": "_bardiconlineoffer__layout",
      "component": () => import('./_bardiconlineoffer__layout_svelte.js').then(m => m._bardiconlineoffer__layout)
    },
    {
      "isDir": true,
      "children": [
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_druid__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/druid/country",
          "id": "_druid_country",
          "component": () => import('./_druid__layout_svelte.js').then(m => m._druid_country)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_druid__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/druid/details",
          "id": "_druid_details",
          "component": () => import('./_druid__layout_svelte.js').then(m => m._druid_details)
        },
        {
          "isIndex": true,
          "isPage": true,
          "meta": {
            "$$bundleId": "_druid__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/druid/index",
          "id": "_druid_index",
          "component": () => import('./_druid__layout_svelte.js').then(m => m._druid_index)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_druid__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/druid/package",
          "id": "_druid_package",
          "component": () => import('./_druid__layout_svelte.js').then(m => m._druid_package)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_druid__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/druid/payment",
          "id": "_druid_payment",
          "component": () => import('./_druid__layout_svelte.js').then(m => m._druid_payment)
        }
      ],
      "isLayout": true,
      "ownMeta": {
        "bundle": true
      },
      "meta": {
        "bundle": true,
        "$$bundleId": "_druid__layout_svelte.js",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/druid",
      "id": "_druid__layout",
      "component": () => import('./_druid__layout_svelte.js').then(m => m._druid__layout)
    },
    {
      "isDir": true,
      "children": [
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_hearth__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/hearth/country",
          "id": "_hearth_country",
          "component": () => import('./_hearth__layout_svelte.js').then(m => m._hearth_country)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_hearth__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/hearth/details",
          "id": "_hearth_details",
          "component": () => import('./_hearth__layout_svelte.js').then(m => m._hearth_details)
        },
        {
          "isIndex": true,
          "isPage": true,
          "meta": {
            "$$bundleId": "_hearth__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/hearth/index",
          "id": "_hearth_index",
          "component": () => import('./_hearth__layout_svelte.js').then(m => m._hearth_index)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_hearth__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/hearth/package",
          "id": "_hearth_package",
          "component": () => import('./_hearth__layout_svelte.js').then(m => m._hearth_package)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_hearth__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/hearth/payment",
          "id": "_hearth_payment",
          "component": () => import('./_hearth__layout_svelte.js').then(m => m._hearth_payment)
        }
      ],
      "isLayout": true,
      "ownMeta": {
        "bundle": true
      },
      "meta": {
        "bundle": true,
        "$$bundleId": "_hearth__layout_svelte.js",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/hearth",
      "id": "_hearth__layout",
      "component": () => import('./_hearth__layout_svelte.js').then(m => m._hearth__layout)
    },
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => import('../src/routes/index.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/more",
      "id": "_more",
      "component": () => import('../src/routes/more.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_oo__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/oo/country",
          "id": "_oo_country",
          "component": () => import('./_oo__layout_svelte.js').then(m => m._oo_country)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_oo__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/oo/details",
          "id": "_oo_details",
          "component": () => import('./_oo__layout_svelte.js').then(m => m._oo_details)
        },
        {
          "isIndex": true,
          "isPage": true,
          "meta": {
            "$$bundleId": "_oo__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/oo/index",
          "id": "_oo_index",
          "component": () => import('./_oo__layout_svelte.js').then(m => m._oo_index)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_oo__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/oo/package",
          "id": "_oo_package",
          "component": () => import('./_oo__layout_svelte.js').then(m => m._oo_package)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_oo__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/oo/payment",
          "id": "_oo_payment",
          "component": () => import('./_oo__layout_svelte.js').then(m => m._oo_payment)
        }
      ],
      "isLayout": true,
      "ownMeta": {
        "bundle": true
      },
      "meta": {
        "bundle": true,
        "$$bundleId": "_oo__layout_svelte.js",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/oo",
      "id": "_oo__layout",
      "component": () => import('./_oo__layout_svelte.js').then(m => m._oo__layout)
    },
    {
      "isDir": true,
      "children": [
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "meta": {
                "$$bundleId": "_ovate__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/ovate/:InviteCode/index",
              "id": "_ovate__InviteCode_index",
              "component": () => import('./_ovate__layout_svelte.js').then(m => m._ovate__InviteCode_index)
            },
            {
              "isPage": true,
              "meta": {
                "$$bundleId": "_ovate__layout_svelte.js",
                "recursive": true,
                "preload": false,
                "prerender": true
              },
              "path": "/ovate/:InviteCode/VerifyCode",
              "id": "_ovate__InviteCode_VerifyCode",
              "component": () => import('./_ovate__layout_svelte.js').then(m => m._ovate__InviteCode_VerifyCode)
            }
          ],
          "meta": {
            "$$bundleId": "_ovate__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/ovate/:InviteCode"
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_ovate__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/ovate/country",
          "id": "_ovate_country",
          "component": () => import('./_ovate__layout_svelte.js').then(m => m._ovate_country)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_ovate__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/ovate/details",
          "id": "_ovate_details",
          "component": () => import('./_ovate__layout_svelte.js').then(m => m._ovate_details)
        },
        {
          "isIndex": true,
          "isPage": true,
          "meta": {
            "$$bundleId": "_ovate__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/ovate/index",
          "id": "_ovate_index",
          "component": () => import('./_ovate__layout_svelte.js').then(m => m._ovate_index)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_ovate__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/ovate/package",
          "id": "_ovate_package",
          "component": () => import('./_ovate__layout_svelte.js').then(m => m._ovate_package)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_ovate__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/ovate/payment",
          "id": "_ovate_payment",
          "component": () => import('./_ovate__layout_svelte.js').then(m => m._ovate_payment)
        }
      ],
      "isLayout": true,
      "ownMeta": {
        "bundle": true
      },
      "meta": {
        "bundle": true,
        "$$bundleId": "_ovate__layout_svelte.js",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/ovate",
      "id": "_ovate__layout",
      "component": () => import('./_ovate__layout_svelte.js').then(m => m._ovate__layout)
    },
    {
      "isDir": true,
      "children": [
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_ovateonlineoffer__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/ovateonlineoffer/country",
          "id": "_ovateonlineoffer_country",
          "component": () => import('./_ovateonlineoffer__layout_svelte.js').then(m => m._ovateonlineoffer_country)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_ovateonlineoffer__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/ovateonlineoffer/details",
          "id": "_ovateonlineoffer_details",
          "component": () => import('./_ovateonlineoffer__layout_svelte.js').then(m => m._ovateonlineoffer_details)
        },
        {
          "isIndex": true,
          "isPage": true,
          "meta": {
            "$$bundleId": "_ovateonlineoffer__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/ovateonlineoffer/index",
          "id": "_ovateonlineoffer_index",
          "component": () => import('./_ovateonlineoffer__layout_svelte.js').then(m => m._ovateonlineoffer_index)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_ovateonlineoffer__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/ovateonlineoffer/package",
          "id": "_ovateonlineoffer_package",
          "component": () => import('./_ovateonlineoffer__layout_svelte.js').then(m => m._ovateonlineoffer_package)
        },
        {
          "isPage": true,
          "meta": {
            "$$bundleId": "_ovateonlineoffer__layout_svelte.js",
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/ovateonlineoffer/payment",
          "id": "_ovateonlineoffer_payment",
          "component": () => import('./_ovateonlineoffer__layout_svelte.js').then(m => m._ovateonlineoffer_payment)
        }
      ],
      "isLayout": true,
      "ownMeta": {
        "bundle": true
      },
      "meta": {
        "bundle": true,
        "$$bundleId": "_ovateonlineoffer__layout_svelte.js",
        "recursive": true,
        "preload": false,
        "prerender": true
      },
      "path": "/ovateonlineoffer",
      "id": "_ovateonlineoffer__layout",
      "component": () => import('./_ovateonlineoffer__layout_svelte.js').then(m => m._ovateonlineoffer__layout)
    },
    {
      "isPage": true,
      "path": "/package",
      "id": "_package",
      "component": () => import('../src/routes/package.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/stripe-portal-access",
      "id": "_stripePortalAccess",
      "component": () => import('../src/routes/stripe-portal-access.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isPage": true,
          "path": "/success/bardic",
          "id": "_success_bardic",
          "component": () => import('../src/routes/success/bardic.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/success/bardiconlineoffer",
          "id": "_success_bardiconlineoffer",
          "component": () => import('../src/routes/success/bardiconlineoffer.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/success/druid-ovate",
          "id": "_success_druidOvate",
          "component": () => import('../src/routes/success/druid-ovate.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/success/hearth",
          "id": "_success_hearth",
          "component": () => import('../src/routes/success/hearth.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/success/ovateonlineoffer",
          "id": "_success_ovateonlineoffer",
          "component": () => import('../src/routes/success/ovateonlineoffer.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/success/touchstone",
          "id": "_success_touchstone",
          "component": () => import('../src/routes/success/touchstone.svelte').then(m => m.default)
        }
      ],
      "isLayout": true,
      "path": "/success",
      "id": "_success__layout",
      "component": () => import('../src/routes/success/_layout.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isPage": true,
          "path": "/touchstone/country",
          "id": "_touchstone_country",
          "component": () => import('../src/routes/touchstone/country.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/touchstone/details",
          "id": "_touchstone_details",
          "component": () => import('../src/routes/touchstone/details.svelte').then(m => m.default)
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/touchstone/index",
          "id": "_touchstone_index",
          "component": () => import('../src/routes/touchstone/index.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/touchstone/package",
          "id": "_touchstone_package",
          "component": () => import('../src/routes/touchstone/package.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/touchstone/payment",
          "id": "_touchstone_payment",
          "component": () => import('../src/routes/touchstone/payment.svelte').then(m => m.default)
        }
      ],
      "isLayout": true,
      "path": "/touchstone",
      "id": "_touchstone__layout",
      "component": () => import('../src/routes/touchstone/_layout.svelte').then(m => m.default)
    }
  ],
  "isLayout": true,
  "path": "/",
  "id": "__layout",
  "component": () => import('../src/routes/_layout.svelte').then(m => m.default)
}


export const {tree, routes} = buildClientTree(_tree)

